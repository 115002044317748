import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Field, Form, Formik } from 'formik';
import Frame from '../../assets/img/Frame.svg';
import Note from '../../assets/img/note.svg';
import Calender from '../../assets/img/calendar.svg';
import Target from '../../assets/img/Target.svg';
import Barcode from '../../assets/img/barcode.svg';
import X from '../../assets/img/x.svg';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useERC20Method from '../../hooks/useERC20ContractMethod';
import useOutsideClick from '../../hooks/useOutsideClick';
import { SuccessfulModal, TokenCreationModal } from '../Modal';

interface DeployContractProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  project: any;
}

const DeployContract: React.FC<DeployContractProps> = ({
  isOpen,
  setIsOpen,
  project,
}) => {
  const decimalFactorINOSale = 10000;
  const defaultLiquidityFactor = 0.5;
  const isINO = project.sale_version === 3 || project.sale_version === 5;
  const handleDismiss = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, contentRef, () => {
    isOpen && handleDismiss();
  });

  const [isDeployed, setIsDeployed] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [isMigrating, setIsMigrating] = useState(false);
  const [deployModalOpended, setDeployModalOpended] = useState(false);

  const [toggleTokenCreationModal, setToggleTokenCreationModal] =
    useState(false);
  const [icoTokenAddress, setIcoTokenAddress] = useState('');

  const {
    admin: { deployContractsForProject, migrateToProduction },
  } = useFantomStarterAdminApi();
  const [errors, setErrors] = useState({});
  const { getDecimals } = useERC20Method(project?.chain || 4002);
  const validateForm = (values: any) => {
    const validationErrors = {} as any;
    if (!values.start_date_funding)
      validationErrors.start_date_funding = 'Required';
    if (!values.end_date_funding)
      validationErrors.end_date_funding = 'Required';
    if (!values.pay_token_address)
      validationErrors.pay_token_address = 'Required';
    if (!values.price_per_item) validationErrors.price_per_item = 'Required';
    if (!values.tokens_for_sale) validationErrors.tokens_for_sale = 'Required';

    if (Object.keys(validationErrors).length === 0) {
      return null;
    }

    return validationErrors;
  };

  const options = [
    { value: 4002, label: 'FTM (testnet)' },
    { value: 97, label: 'BSC (testnet)' },
    { value: 80002, label: 'Polygon (testnet)' },
  ];

  const extractDateMonthAndTime = (timestamp: any) => {
    if (timestamp === undefined || timestamp === null) {
      return 'Not Available Now';
    }

    const dateObj = new Date(timestamp);

    const date = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const time = dateObj.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${date} ${month}, ${time}`;
  };

  const [isKYCChecked, setIsKYCChecked] = useState(false);
  const [isStakeChecked, setIsStakeChecked] = useState(false);

  const [kycValue, setKycValue] = useState('no');
  const [stakeValue, setStakeValue] = useState('no');

  const handleToggleKYC = (e: any) => {
    e.preventDefault();
    setIsKYCChecked(!isKYCChecked);
  };

  const handleToggleStake = (e: any) => {
    e.preventDefault();
    setIsStakeChecked(!isStakeChecked);
  };

  const openCustomisePage = () => {
    const chainId = Number(project.chain);
    const isTestnet =
      chainId === 4002 ||
      chainId === 80002 ||
      chainId === 97 ||
      chainId === 5 ||
      chainId === 421611 ||
      chainId === 420 ||
      chainId === 43113;
    const isINO = project.sale_version === 3 || project.sale_version === 5;
    window.open(
      `https://embed.futurestarter.xyz/?path=/story/pages-projectpage--default&args=id:${project.id};isTestnet:${isTestnet};isINO:${isINO}`,
      '_blank'
    );
  };

  const networkToDefaultPayTokenAddress: {
    [chainId: number]: string;
  } = {
    4002: '0xf1277d1ed8ad466beddf92ef448a132661956621',
    250: '0x1B6382DBDEa11d97f24495C9A90b7c88469134a4',
  };

  const defaultPayTokenAddress =
    networkToDefaultPayTokenAddress[Number(project.chain)] || '';

  const isMainnet = Number(project.chain) === 250;

  useEffect(() => {
    const tokenAddress = localStorage.getItem(
      `${project.name}-${project.ido_token_symbol}`
    );
    console.log(tokenAddress);
    if (tokenAddress) {
      setIcoTokenAddress(tokenAddress);
    }
  }, [project.name, project.ido_token_symbol]);

  return (
    <>
      {isOpen && (
        <div>
          <div
            ref={modalRef}
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            {isDeployed && !deployModalOpended && (
              <SuccessfulModal
                onClose={() => setIsOpen(false)}
                onDone={() => setDeployModalOpended(true)}
              />
            )}
            {toggleTokenCreationModal && (
              <TokenCreationModal
                project={project}
                onClose={() => {
                  setToggleTokenCreationModal(false);
                }}
                onDone={(deployedTokenAddress) => {
                  setIcoTokenAddress(deployedTokenAddress);
                  setToggleTokenCreationModal(false);
                }}
              />
            )}

            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div
                style={{ flex: '1', maxHeight: '100vh' }}
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-transparent outline-none focus:outline-none"
              >
                <div
                  className={`MainFrame w-[380px] sm:w-[420px] lg:w-[783px]  p-[20px] sm:p-[30px] bg-zinc-900 rounded-[30px] justify-center sm:justify-start items-start gap-[30px] inline-flex`}
                >
                  <Formik
                    enableReinitialize
                    initialValues={{
                      start_date_funding: project?.start_date_funding || '',
                      end_date_funding: project?.end_date_funding || '',
                      price_per_item:
                        project.sale_version === 8 ||
                        project.sale_version === 10
                          ? Number(project.ratio_paytoken_per_idotoken) *
                            defaultLiquidityFactor
                          : Number(project.ratio_paytoken_per_idotoken) || '',
                      tokens_for_sale:
                        project.sale_version === 8 ||
                        project.sale_version === 10
                          ? Number(project.cap_total_dollars) /
                            Number(project.ratio_paytoken_per_idotoken) /
                            defaultLiquidityFactor
                          : Number(project.cap_total_dollars) /
                              Number(project.ratio_paytoken_per_idotoken) || '',
                      pay_token_address:
                        project.sale_version === 8 ||
                        project.sale_version === 10
                          ? defaultPayTokenAddress
                          : '',
                      ico_token_address: icoTokenAddress,
                      sale_type: 'fcfs',
                      stake_required: stakeValue,
                      kyc_required: kycValue,
                      max_items_per_user: isINO
                        ? Number(project.cap_individual_dollars) /
                          Number(project.ratio_paytoken_per_idotoken)
                        : project?.cap_individual_dollars || 1,
                      max_winners: 1000,
                      chain: project?.chain || 4002,
                    }}
                    onSubmit={async (values) => {
                      if (isDeployed) {
                        openCustomisePage();
                        return;
                      }

                      setErrors({});

                      // validate form
                      const validationErrors = validateForm(values);
                      if (validationErrors) {
                        return setErrors(validationErrors);
                      }

                      // check if already deployed for testnet and migrate
                      if (project.sale_contract_address) {
                        setIsMigrating(true);
                        const response = await migrateToProduction({
                          paytoken_contract_address: values.pay_token_address,
                          token_contract_address: values.ico_token_address,
                          ratio_paytoken_per_idotoken:
                            project.sale_version === 8 ||
                            project.sale_version === 10
                              ? Number(values.price_per_item) /
                                defaultLiquidityFactor
                              : values.price_per_item,
                          cap_total_dollars:
                            project.sale_version === 8 ||
                            project.sale_version === 10
                              ? Number(values.tokens_for_sale) *
                                Number(values.price_per_item) *
                                defaultLiquidityFactor
                              : Number(values.tokens_for_sale) *
                                Number(values.price_per_item),
                          cap_individual_dollars: values.max_items_per_user,
                          ...project,
                        });
                        setIsMigrating(false);
                        setErrors({ api: response.data.result.message });
                        return;
                      }

                      setIsDeploying(true);
                      // Check contracts
                      let icoTokenDecimals = null;
                      if (
                        project.sale_version === 4 ||
                        project.sale_version === 6 ||
                        project.sale_version === 8 ||
                        project.sale_version === 10
                      ) {
                        try {
                          icoTokenDecimals = await getDecimals(
                            values.ico_token_address
                          );
                        } catch (e) {
                          console.error(e);
                        }
                      }
                      let payTokenDecimals = null;
                      try {
                        payTokenDecimals = await getDecimals(
                          values.pay_token_address
                        );
                      } catch (e) {
                        console.error(e);
                      }

                      if (
                        (!icoTokenDecimals &&
                          (project.sale_version === 4 ||
                            project.sale_version === 6 ||
                            project.sale_version === 8 ||
                            project.sale_version === 10)) ||
                        !payTokenDecimals
                      ) {
                        const errors = {} as any;
                        if (
                          !icoTokenDecimals &&
                          (project.sale_version === 4 ||
                            project.sale_version === 6 ||
                            project.sale_version === 8 ||
                            project.sale_version === 10)
                        )
                          errors.icotoken = 'failed to retrieve contract data';
                        if (!payTokenDecimals)
                          errors.paytoken = 'failed to retrieve contract data';
                        setErrors(errors);
                        return;
                      }

                      console.log(values);
                      const response = await deployContractsForProject(
                        {
                          ...values,
                          id: project.id,
                          pay_token_decimals: payTokenDecimals,
                          ico_token_decimals: icoTokenDecimals,
                          sale_version: project.sale_version,
                          price_per_item: isINO
                            ? Number(values.price_per_item) *
                              decimalFactorINOSale
                            : project.sale_version === 8 ||
                                project.sale_version === 10
                              ? Number(values.price_per_item) /
                                defaultLiquidityFactor
                              : values.price_per_item,
                          tokens_for_sale: isINO
                            ? Number(values.tokens_for_sale)
                            : project.sale_version === 8 ||
                                project.sale_version === 10
                              ? Number(values.tokens_for_sale) *
                                defaultLiquidityFactor
                              : values.tokens_for_sale,
                          is_also_liquidity_provider:
                            project.sale_version === 8 ||
                            project.sale_version === 10
                              ? 'yes'
                              : 'no',
                        },
                        project.sale_version === 4 ||
                          project.sale_version === 6 ||
                          project.sale_version === 8 ||
                          project.sale_version === 10
                          ? 'ico'
                          : 'ino'
                      );
                      setIsDeploying(false);
                      console.warn({ api: response.data.result.message });
                      setErrors({ api: response.data.result.message });
                      response.data.result.err
                        ? setIsDeployed(false)
                        : setIsDeployed(true);
                    }}
                  >
                    {({ isSubmitting, handleChange, values }) => (
                      <Form>
                        <div className="Frame5729 flex-col justify-start items-start gap-[30px] inline-flex">
                          <div className="Frame15 self-stretch  justify-between items-center gap-[8px] sm:gap-[15px] inline-flex">
                            <div className="Label text-white text-xl font-semibold">
                              Deploy project contract
                            </div>
                            {/* <div className='Frame5702 h-[38px] justify-end items-start  gap-2 hidden lg:inline-flex'>
															<button
																onClick={() => {
																	console.log(values)
																	setIsOpen(false);
																	setErrors({});
																	setIsKYCChecked(false);
																	setIsStakeChecked(false);
																	setKycValue('no');
																	setStakeValue('no');
																}}
																className='Button3 px-[18px] py-2.5  justify-start items-center gap-1.5 flex'>
																<div className='Label text-center text-purple-500 text-sm font-semibold leading-[18px]'>
																	Cancel
																</div>
															</button>
															<button
																disabled={isSubmitting || isDeployed}
																type='submit'
																className='Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex'>
																<div className='Label text-center text-black text-sm font-semibold leading-[18px]'>
																	{isDeploying
																		? 'Deploying...'
																		: isDeployed
																		? 'Success!'
																		: 'Deploy contracts'}
																</div>
															</button>
														</div> */}

                            <button
                              onClick={() => {
                                setIsOpen(false);
                                setErrors({});
                                setIsKYCChecked(false);
                                setIsStakeChecked(false);
                                setKycValue('no');
                                setStakeValue('no');
                              }}
                              className="Frame5702 h-6 justify-end items-start flex"
                            >
                              <div className="X w-6 h-6 relative">
                                <img src={X} className=" w-6 h-6" alt="x" />
                              </div>
                            </button>
                          </div>
                          {/* {errors && (
														<Typo1
															style={{
																color: 'red',
																maxWidth: '600px',
																wordWrap: 'break-word',
															}}
															className='hidden lg:inline-flex'>
															{Object.keys(errors).length > 0 &&
																JSON.stringify(errors, null, 2)}
														</Typo1>
													)} */}
                          <div className="Frame5728 justify-start items-start gap-[30px] inline-flex flex-col lg:flex-row">
                            <div className="Frame5727 flex-col justify-start items-start gap-[30px] inline-flex">
                              <div className="Frame5722 self-stretch flex-col justify-start items-start gap-[15px] flex">
                                <div className="Frame5704 justify-start items-center gap-2.5 inline-flex">
                                  <div className="VuesaxBoldNote2 w-7 h-7 justify-center items-center flex">
                                    <div className="VuesaxBoldNote2 w-7 h-7 relative">
                                      <img
                                        src={Note}
                                        alt={Note}
                                        className="w-7 h-7"
                                      />
                                    </div>
                                  </div>
                                  <div className="Info flex-col justify-start items-start inline-flex">
                                    <div className="Name text-center text-neutral-400 text-xs font-normal">
                                      Name
                                    </div>
                                    <div className="StumbleUponRumbleTestV2 text-white text-xs font-normal">
                                      {project?.name}
                                    </div>
                                  </div>
                                </div>
                                <div className="Frame5705 justify-start items-center gap-2.5 inline-flex">
                                  <div className="VuesaxBoldBarcode w-7 h-7 justify-center items-center flex">
                                    <div className="Barcode w-7 h-7 relative">
                                      <img
                                        src={Barcode}
                                        alt={Barcode}
                                        className="w-7 h-7"
                                      />
                                    </div>
                                  </div>
                                  <div className="Info flex-col justify-start items-start inline-flex">
                                    <div className="UniqueId text-center text-neutral-400 text-xs font-normal">
                                      Unique ID
                                    </div>
                                    <div className="A0831d223b1C4adF82ad7938483 text-white text-xs font-normal">
                                      {project?.id}
                                    </div>
                                  </div>
                                </div>
                                <div className="Frame5706 justify-start items-center gap-2.5 inline-flex">
                                  <div className="Frame w-[27.39px] h-7 relative">
                                    <img
                                      src={Frame}
                                      alt={Frame}
                                      className="w-[27.39px] h-7"
                                    />
                                  </div>
                                  <div className="Info flex-col justify-start items-start inline-flex">
                                    <div className="Type text-center text-neutral-400 text-xs font-normal">
                                      Type
                                    </div>
                                    <div className="NftSale text-white text-xs font-normal">
                                      {project?.sale_version === 4 ||
                                      project?.sale_version === 6 ||
                                      project?.sale_version === 8 ||
                                      project?.sale_version === 10
                                        ? 'Token sale'
                                        : 'NFT sale'}
                                    </div>
                                  </div>
                                </div>
                                <div className="Frame5696  flex-col justify-start items-start gap-1.5 inline-flex">
                                  <div className="SaleDate text-center text-neutral-400 text-xs font-normal">
                                    Sale Date
                                  </div>
                                  <div className="Frame5695 justify-start items-center gap-[10px] inline-flex">
                                    <div className="Frame5670 justify-start items-center gap-1.5 flex">
                                      <div className="VuesaxBoldCalendarjustify-center items-center flex">
                                        <div className="Calendar relative">
                                          <img
                                            src={Calender}
                                            alt={Calender}
                                            className="w-[15px] h-[15px]"
                                          />
                                        </div>
                                      </div>
                                      <div className="Start24Jun0232Pm">
                                        <span className="text-neutral-400 text-xs font-normal">
                                          Start:{' '}
                                        </span>
                                        <span className="text-white text-xs font-normal">
                                          {extractDateMonthAndTime(
                                            project?.start_date_funding
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="Frame5670 justify-start items-center gap-1.5 flex">
                                      <div className="Layer1  relative">
                                        <img
                                          src={Target}
                                          alt={Target}
                                          className="w-[15px] h-[15px]"
                                        />
                                      </div>
                                      <div className="">
                                        <span className="text-neutral-400 text-xs font-normal">
                                          End:
                                        </span>
                                        <span className="text-white text-xs font-normal">
                                          {' '}
                                          {extractDateMonthAndTime(
                                            project?.end_date_funding
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Frame5737 self-stretch h-[147px] flex-col justify-start items-start gap-5 flex">
                                <div className="Frame19 self-stretch h-[62px]  flex-col justify-start items-start gap-2.5 flex">
                                  <div className="Label text-neutral-400 text-sm font-normal">
                                    Chain
                                  </div>

                                  <div className="Card w-[302px] h-[35px]   justify-start  inline-flex cursor-pointer">
                                    <Field
                                      as="select"
                                      name="chain"
                                      onChange={handleChange}
                                      className="Frame38 bg-neutral-900  rounded-[10px] border border-neutral-700 w-[302px] h-[35px] justify-start items-center gap-[5px] flex px-2 text-white  text-sm cursor-pointer"
                                    >
                                      <option
                                        className="Label   w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                        selected
                                        disabled
                                      >
                                        Select Chain
                                      </option>
                                      <option
                                        value={250}
                                        className="Label  w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Fantom
                                      </option>
                                      <option
                                        value={4002}
                                        className="Label  w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Fantom (testnet)
                                      </option>
                                      <option
                                        value={137}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Polygon
                                      </option>
                                      <option
                                        value={80002}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Polygon (testnet)
                                      </option>
                                      <option
                                        value={8453}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Base
                                      </option>
                                      <option
                                        value={84532}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Base (testnet)
                                      </option>
                                      <option
                                        value={81457}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Blast
                                      </option>
                                      <option
                                        value={168587773}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Blast Sepolia (testnet)
                                      </option>
                                      <option
                                        value={97}
                                        className="Label   w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        BSC (testnet)
                                      </option>
                                      <option
                                        value={56}
                                        className="Label   w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        BSC
                                      </option>
                                      <option
                                        value={11155111}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Sepolia (testnet)
                                      </option>
                                      <option
                                        value={3441005}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Manta Pacific (testnet)
                                      </option>

                                      <option
                                        value={919}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Mode Sepolia (testnet)
                                      </option>
                                      <option
                                        value={64165}
                                        className="Label w-[302px] h-[19px] text-white text-sm font-normal cursor-pointer"
                                      >
                                        Sonic (testnet)
                                      </option>
                                    </Field>
                                  </div>
                                </div>

                                <div className="Frame20 self-stretch h-[65px]  flex-col justify-start items-start flex">
                                  <div className="Label text-neutral-400 text-sm font-normal">
                                    Sale type
                                  </div>
                                  <div
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                    className="Frame5735 self-stretch justify-between items-start gap-2.5 inline-flex"
                                  >
                                    <div className="Frame5732 justify-start items-center flex">
                                      <div className="RadioButtons h-12 pl-[1.50px] pr-[9.50px] py-[15px] flex-col justify-center items-center inline-flex">
                                        <label className="Icon w-[18px] h-[18px] relative flex-col justify-start items-start flex">
                                          <Field
                                            type="radio"
                                            name="sale_type"
                                            value="fcfs"
                                            className="radio border-purple-500 checked:bg-purple-500  w-[18px] h-[18px]"
                                          />
                                        </label>
                                      </div>
                                      <label
                                        htmlFor="FCFS"
                                        className="Label text-white text-sm font-normal"
                                      >
                                        Open
                                      </label>
                                    </div>
                                    <div className="Frame5735 pl-5 justify-start items-center flex">
                                      <div className="RadioButtons h-12 pl-[1.50px] pr-[9.50px] py-[15px] flex-col justify-center items-center inline-flex">
                                        <label className="Icon w-[18px] h-[18px] relative flex-col justify-start items-start flex">
                                          <Field
                                            type="radio"
                                            name="sale_type"
                                            value="whitelist"
                                            className="radio  border-purple-500 checked:bg-purple-500  w-[18px] h-[18px] "
                                          />
                                        </label>
                                      </div>
                                      <label
                                        htmlFor="WHITELIST"
                                        className="Label text-white text-sm font-normal"
                                      >
                                        Registered
                                      </label>
                                    </div>
                                    <div className="Frame5736 pl-5 justify-start items-center flex">
                                      <div className="RadioButtons h-12 pl-[1.50px] pr-[9.50px] py-[15px] flex-col justify-center items-center inline-flex">
                                        <label className="Icon w-[18px] h-[18px] relative flex-col justify-start items-start flex">
                                          <Field
                                            type="radio"
                                            name="sale_type"
                                            value="lottery"
                                            className="radio border-purple-500 checked:bg-purple-500  w-[18px] h-[18px]  "
                                          />
                                        </label>
                                      </div>
                                      <label
                                        htmlFor="LOTTERY"
                                        className="Label text-white text-sm font-normal"
                                      >
                                        Lottery
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Rectangle3326 w-px self-stretch bg-zinc-800" />
                            <div className="Frame5726 flex-col justify-start items-start gap-[30px] inline-flex">
                              <div className="Frame5723 self-stretch  flex-col justify-start items-start gap-5 flex">
                                <div className="Frame20 self-stretch h-[60px] flex-col justify-start items-start gap-2.5 flex">
                                  <div className="Label text-neutral-400 text-sm font-normal">
                                    Payment token address
                                  </div>
                                  <div className="Card self-stretch w-[310px] sm:w-full  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex m-0">
                                    <Field
                                      name="pay_token_address"
                                      placeholder="0x00..."
                                      autoComplete="off"
                                      className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                      // disabled={
                                      //   (project.sale_version === 8 ||
                                      //     project.sale_version === 10) &&
                                      //   isMainnet
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="Frame20 self-stretch flex-col justify-start items-start gap-2.5 flex">
                                  <div className="Label text-neutral-400 text-sm font-normal">
                                    {isINO
                                      ? 'ERC1155 collection address'
                                      : 'Sold token address'}
                                  </div>
                                  {!isINO && (
                                    <section
                                      onClick={() =>
                                        //window.open(
                                        // 'https://interchain.axelar.dev/',
                                        //</div>  '_blank'
                                        //)
                                        setToggleTokenCreationModal(true)
                                      }
                                      className="w-full flex gap-2.5 items-start py-2.5 px-5 text-white bg-black rounded-3xl border-2 border-indigo-700 border-solid z-10 cursor-pointer"
                                    >
                                      <div className="flex flex-col grow shrink-0 basis-0">
                                        <h3 className="text-xs font-medium">
                                          No token? No problem!
                                        </h3>
                                        <p className="text-xs">
                                          Mint an Interchain token with Axelar.
                                        </p>
                                      </div>
                                    </section>
                                  )}
                                  <div className="Card self-stretch w-[310px] sm:w-full  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex m-0">
                                    <Field
                                      name="ico_token_address"
                                      placeholder="0x00..."
                                      autoComplete="off"
                                      className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                    />
                                  </div>
                                </div>

                                <div className="Frame5730 justify-start items-start gap-5 inline-flex">
                                  <div className="Frame15  flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="Label text-neutral-400 text-sm font-normal">
                                      Price per{' '}
                                      {project.sale_version === 4 ||
                                      project.sale_version === 6 ||
                                      project.sale_version === 8 ||
                                      project.sale_version === 10
                                        ? 'token'
                                        : 'items (in USD)'}
                                    </div>
                                    <div className="Card w-[145px] sm:w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                      <Field
                                        name="price_per_item"
                                        placeholder="100"
                                        autoComplete="off"
                                        className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                      />
                                    </div>
                                  </div>
                                  <div className="Frame21  flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="Label text-neutral-400 text-sm font-normal">
                                      Max{' '}
                                      {project.sale_version === 4 ||
                                      project.sale_version === 6 ||
                                      project.sale_version === 8 ||
                                      project.sale_version === 10
                                        ? 'investment'
                                        : 'items'}{' '}
                                      per user
                                    </div>
                                    <div className="Card w-[145px] sm:w-[170px] bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                      <Field
                                        name="max_items_per_user"
                                        placeholder="50000"
                                        autoComplete="off"
                                        className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="Frame5731 self-stretch h-[60px]  flex-col justify-start items-start gap-2.5 flex">
                                  <div className="Label text-neutral-400 text-sm font-normal">
                                    Total{' '}
                                    {project.sale_version === 4 ||
                                    project.sale_version === 6 ||
                                    project.sale_version === 8 ||
                                    project.sale_version === 10
                                      ? 'tokens'
                                      : 'items'}{' '}
                                    for sale
                                  </div>
                                  <div className="Card self-stretch w-[310px] sm:w-full  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex m-0">
                                    <Field
                                      name="tokens_for_sale"
                                      placeholder="50000"
                                      autoComplete="off"
                                      className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                    />
                                  </div>
                                </div>
                              </div>

                              {values.sale_type !== 'fcfs' && (
                                <div className="Frame5725 self-stretch justify-between items-center gap-[15px] inline-flex">
                                  <div className="Card w-[145px] sm:w-[170px] h-[84px] p-3.5 rounded-[10px] border border-neutral-700 flex-col justify-between items-start inline-flex">
                                    <div className="Frame38 justify-start items-center gap-[5px] inline-flex">
                                      <div className="Label text-neutral-400 text-sm font-normal">
                                        Stake required
                                      </div>
                                    </div>
                                    <div className="relative Frame5724 self-stretch justify-between items-start gap-2.5 inline-flex">
                                      <div className="Label text-white text-sm font-normal">
                                        {isStakeChecked ? 'Yes' : 'No'}
                                      </div>
                                      <div
                                        className="relative inline-block"
                                        onClick={(e) => handleToggleStake(e)}
                                      >
                                        <Field
                                          type="radio"
                                          name="stake_required"
                                          value={isStakeChecked ? 'no' : 'yes'}
                                          className={`peer pl-[22.35px] pr-[1.35px] py-[1.35px] w-[42px] h-[21px] cursor-pointer appearance-none rounded-full  ${
                                            isStakeChecked
                                              ? 'bg-purple-500'
                                              : 'bg-neutral-700'
                                          }  `}
                                        />

                                        <span
                                          className={`pointer-events-none absolute  top-[1.5px] block w-[18.29px] h-[18.29px] rounded-full bg-white transition-all duration-200 ${
                                            isStakeChecked
                                              ? 'transform translate-x-[22px]'
                                              : 'transform translate-x-[0px]'
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Card w-[145px] sm:w-[170px] h-[84px] p-3.5 rounded-[10px] border border-neutral-700 flex-col justify-between items-start inline-flex">
                                    <div className="Frame38 justify-start items-center gap-[5px] inline-flex">
                                      <div className="Label text-neutral-400 text-sm font-normal">
                                        KYC required
                                      </div>
                                    </div>
                                    <div className="relative Frame5724 self-stretch justify-between items-start gap-2.5 inline-flex">
                                      <div className="Label text-white text-sm font-normal">
                                        {isKYCChecked ? 'Yes' : 'No'}
                                      </div>

                                      <div
                                        className="relative inline-block"
                                        onClick={(e) => handleToggleKYC(e)}
                                      >
                                        <Field
                                          type="radio"
                                          name="kyc_required"
                                          value={isKYCChecked ? 'no' : 'yes'}
                                          className={`peer pl-[22.35px] pr-[1.35px] py-[1.35px] w-[42px] h-[21px] cursor-pointer appearance-none rounded-full  ${
                                            isKYCChecked
                                              ? 'bg-purple-500'
                                              : 'bg-neutral-700'
                                          }  `}
                                        />
                                        <span
                                          className={`pointer-events-none absolute  top-[1.5px] block w-[18.29px] h-[18.29px] rounded-full bg-white transition-all duration-200 ${
                                            isKYCChecked
                                              ? 'transform translate-x-[22px]'
                                              : 'transform translate-x-[0px]'
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {values.sale_type === 'lottery' &&
                                (project.sale_version === 4 ||
                                  project.sale_version === 6 ||
                                  project.sale_version === 8 ||
                                  project.sale_version === 10) && (
                                  <div className="Frame5731 self-stretch h-[60px]  flex-col justify-start items-start gap-2.5 flex">
                                    <div className="Label text-neutral-400 text-sm font-normal">
                                      Max Winners
                                    </div>
                                    <div className="Card self-stretch w-[310px] sm:w-full bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                      <Field
                                        name="max_winners"
                                        autoComplete="off"
                                        placeholder="50000"
                                        className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-500 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="Frame15 self-stretch relative justify-between items-center gap-[15px] inline-flex">
                            <div className="Label text-white text-xl font-semibold"></div>
                            <div className="Frame5702 h-[38px] justify-end items-start  gap-2 inline-flex">
                              <button
                                onClick={() => {
                                  setIsOpen(false);
                                  setErrors({});
                                  setIsKYCChecked(false);
                                  setIsStakeChecked(false);
                                  setKycValue('no');
                                  setStakeValue('no');
                                }}
                                className="Button3 px-[18px] py-2.5  justify-start items-center gap-1.5 flex"
                              >
                                <div className="Label text-center text-purple-500 text-sm font-semibold leading-[18px]">
                                  Cancel
                                </div>
                              </button>
                              <button
                                disabled={isSubmitting || isMigrating}
                                type="submit"
                                className="Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex"
                              >
                                <div className="Label text-center text-black text-sm font-semibold leading-[18px]">
                                  {project.sale_contract_address
                                    ? isMigrating
                                      ? 'Migrating...'
                                      : 'Migrate to mainnet'
                                    : isDeploying
                                      ? 'Deploying...'
                                      : isDeployed
                                        ? 'Customize page'
                                        : 'Deploy contracts'}
                                </div>
                              </button>
                            </div>
                          </div>
                          {/* {errors && (
                            <Typo1
                              style={{
                                color: 'red',
                                maxWidth: '100%',
                                wordWrap: 'break-word',
                              }}
                              className=""
                            >
                              {Object.keys(errors).length > 0 &&
                                JSON.stringify(errors, null, 2)}
                            </Typo1>
                          )} */}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]" />
        </div>
      )}
    </>
  );
};

export default DeployContract;
